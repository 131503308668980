import {defineStore} from 'pinia';
import {
  addDocument,
  fetchDocumentOnServer,
  fetchDocuments,
  fetchTrashDocuments,
  getSignedUrlForDocument,
  hardDeleteDocumentOnServer,
  restoreDocumentOnServer,
  searchDocumentsOnServer,
  searchTags,
  softDeleteDocumentOnServer,
  subscribeToDocumentUpdates,
  subscribeToTrashDocumentUpdates,
  unsubscribeFromDocumentUpdates,
  unsubscribeFromTrashDocumentUpdates,
  updateDocumentOnServer,
  updateDocumentTags
} from '~/lib/services/document.service';

// Import sender store
import {useSenderStore} from './sender.store';

export const useDocumentStore = defineStore('document', {
  state: () => ({
    documents: [],
    currentDocument: null,
    filteredDocuments: [],
    trashDocuments: [],
    isLoading: false,
    isSearching: false,
    subscription: null,
    trashSubscription: null,
    error: null,
    isSubscribed: false
  }),
  actions: {
    /**
     * Adds a new document to the database
     * @param {Object} document - The document object to add
     * @throws {Error} If document creation fails
     */
    async addDocument(document) {
      try {
        this.isLoading = true;
        await addDocument(document);
        await this.fetchDocuments();
      } catch (error) {
        console.error('Error adding document:', error);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Fetches all non-deleted documents for the current user
     * @throws {Error} If fetching documents fails
     */
    async fetchDocuments() {
      this.isLoading = true;
      try {
        this.documents = await fetchDocuments();
        this.documents = this.documents.filter(doc => !doc.deleted_at);
      } catch (error) {
        this.error = error.message;
        console.error('Error fetching documents:', error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Fetches all soft-deleted documents for the current user
     * @throws {Error} If fetching trash documents fails
     */
    async fetchTrashDocuments() {
      this.isLoading = true;
      try {
        this.trashDocuments = await fetchTrashDocuments();
      } catch (error) {
        this.error = error.message;
        console.error('Error fetching trashed documents:', error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Subscribes to real-time document updates
     * @returns {Object} Subscription object
     */
    async subscribeToDocumentUpdates() {
      if (this.isSubscribed) {
        console.log('Already subscribed to document updates');
        return;
      }

      console.log('Starting document subscription...');

      try {
        // First unsubscribe to prevent any duplicate subscriptions
        if (this.subscription) {
          console.log('Cleaning up existing subscription...');
          await unsubscribeFromDocumentUpdates(this.subscription);
          this.subscription = null;
        }

        this.subscription = await subscribeToDocumentUpdates(payload => {
          console.log('Processing document update:', payload);

          // Ensure we have a valid payload
          if (!payload || !payload.new) {
            console.error('Invalid payload received:', payload);
            return;
          }

          const updatedDocument = payload.new;
          const oldDocument = payload.old;
          const eventType = payload.eventType;

          console.log('Document update details:', {
            eventType,
            documentId: updatedDocument.id,
            changes: updatedDocument
          });

          // Handle different event types
          switch (eventType) {
            case 'INSERT':
              if (!this.documents.some(doc => doc.id === updatedDocument.id)) {
                console.log('Inserting new document:', updatedDocument.id);
                this.documents.push(updatedDocument);
              }
              break;

            case 'UPDATE':
              const index = this.documents.findIndex(doc => doc.id === updatedDocument.id);
              if (index !== -1) {
                console.log('Updating existing document:', updatedDocument.id);
                this.documents[index] = {
                  ...this.documents[index],
                  ...updatedDocument
                };
              }
              break;

            case 'DELETE':
              console.log('Removing document:', oldDocument.id);
              this.documents = this.documents.filter(doc => doc.id !== oldDocument.id);
              break;
          }
        });

        this.isSubscribed = true;
        console.log('Successfully subscribed to document updates');
      } catch (error) {
        console.error('Error subscribing to document updates:', error);
        this.isSubscribed = false;
        this.subscription = null;
      }
    },
    /**
     * Unsubscribes from real-time document updates
     */
    async unsubscribeFromDocumentUpdates() {
      if (!this.isSubscribed || !this.subscription) {
        return;
      }

      try {
        await unsubscribeFromDocumentUpdates(this.subscription);
        this.subscription = null;
        this.isSubscribed = false;
        console.log('Successfully unsubscribed from document updates');
      } catch (error) {
        console.error('Error unsubscribing from document updates:', error);
      }
    },
    /**
     * Handles incoming real-time document updates
     * @param {Object} document - The updated document
     */
    handleRemoteDocumentUpdate(payload) {
      if (!payload || !payload.new) {
        console.error('Invalid payload received:', payload);
        return;
      }

      const document = payload.new;

      // If the document is deleted, move it to trash
      if (document.deleted_at) {
        const index = this.documents.findIndex(doc => doc.id === document.id);
        if (index !== -1) {
          const deletedDoc = this.documents.splice(index, 1)[0];
          this.trashDocuments.push(deletedDoc);
        }
        return;
      }

      // Update or add document
      const index = this.documents.findIndex(doc => doc.id === document.id);
      if (index !== -1) {
        // Preserve any local state that might not be in the update
        this.documents[index] = {
          ...this.documents[index],
          ...document
        };
      } else {
        // Only add if it's not deleted
        if (!document.deleted_at) {
          this.documents.push(document);
        }
      }
    },
    /**
     * Subscribes to real-time trash document updates
     * @returns {Object} Subscription object
     */
    async subscribeToTrashDocumentUpdates() {
      this.trashSubscription = subscribeToTrashDocumentUpdates(updatedDocument => {
        this.handleRemoteTrashDocumentUpdate(updatedDocument);
      });
    },
    /**
     * Unsubscribes from real-time trash document updates
     */
    async unsubscribeFromTrashDocumentUpdates() {
      if (this.trashSubscription) {
        await unsubscribeFromTrashDocumentUpdates(await this.trashSubscription);
        this.trashSubscription = null;
      }
    },
    /**
     * Handles incoming real-time trash document updates
     * @param {Object} document - The updated document
     */
    handleRemoteTrashDocumentUpdate(document) {
      if (!document || !document.id) {
        console.error('Invalid document update received:', document);
        return;
      }

      // Check if document has been deleted or restored
      const isDeleted = document.deleted_at !== null;
      const index = this.trashDocuments.findIndex(doc => doc.id === document.id);

      if (isDeleted) {
        if (index !== -1) {
          this.trashDocuments.splice(index, 1);
        }
      } else {
        this.trashDocuments.push(document);
      }
    },
    /**
     * Fetches a specific document by ID
     * @param {string} id - Document ID to fetch
     * @returns {Promise<Object>} The fetched document
     * @throws {Error} If document fetch fails
     */
    async fetchDocumentById(id) {
      return new Promise((resolve, reject) => {
        fetchDocumentOnServer(id)
          .then(document => {
            this.currentDocument = document;

            const index = this.documents.findIndex(doc => doc.id === document.id);
            if (index !== -1) {
              this.documents[index] = {...this.documents[index], ...document};
            } else {
              this.documents.push(document);
            }
            resolve(document);
          })
          .catch(error => {
            console.error('Error fetching document:', error);
            reject(error);
          });
      });
    },
    /**
     * Updates an existing document
     * @param {Object} document - Document with updated fields
     * @throws {Error} If document update fails
     */
    async updateDocument(document) {
      this.isLoading = true;
      try {
        // Handle the sender update
        if (document.sender) {
          if (!document.sender.id) {
            // This is a new sender, create it first
            const senderStore = useSenderStore();
            const newSender = await senderStore.createSender(document.sender);
            document.sender_id = newSender.id;
          } else {
            // Existing sender, just update the sender_id
            document.sender_id = document.sender.id;
          }
        }

        // Update basic document info
        await updateDocumentOnServer(document);

        // Update tags
        await updateDocumentTags(document);

        // Fetch the updated document
        this.currentDocument = await fetchDocumentOnServer(document.id);

        // Update the document in the documents array
        const index = this.documents.findIndex(d => d.id === document.id);
        if (index !== -1) {
          this.documents[index] = this.currentDocument;
        }
      } catch (error) {
        this.error = error.message;
        console.error('Error updating document:', error);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Soft deletes a document (moves to trash)
     * @param {string} id - Document ID to delete
     * @throws {Error} If deletion fails
     */
    async softDeleteDocument(id) {
      this.isLoading = true;
      try {
        await softDeleteDocumentOnServer(id);

        // Let the real-time subscription handle the update
        // instead of manually modifying the state
      } catch (error) {
        this.error = error.message;
        console.error('Error soft deleting document:', error);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Permanently deletes a document
     * @param {string} id - Document ID to delete
     * @throws {Error} If deletion fails
     */
    async hardDeleteDocument(id) {
      this.isLoading = true;
      try {
        await hardDeleteDocumentOnServer(id);
        this.documents = this.documents.filter(doc => doc.id !== id);
      } catch (error) {
        this.error = error.message;
        console.error('Error soft deleting document:', error);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Restores a document from trash
     * @param {string} id - Document ID to restore
     * @throws {Error} If restoration fails
     */
    async restoreDocument(id) {
      this.isLoading = true;
      try {
        await restoreDocumentOnServer(id);
        const updatedDocument = await fetchDocumentOnServer(id);
        // Update the document in the documents array
        const index = this.documents.findIndex(d => d.id === updatedDocument.id);
        const trashIndex = this.trashDocuments.findIndex(d => d.id === updatedDocument.id);
        if (index !== -1) {
          this.documents[index] = updatedDocument;
        }
        if (trashIndex !== -1) {
          this.trashDocuments.splice(trashIndex, 1);
        }
      } catch (error) {
        this.error = error.message;
        console.error('Error soft deleting document:', error);
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Searches documents based on query
     * @param {string} query - Search query
     * @returns {Promise<Array>} Matching documents
     */
    async searchDocuments(query) {
      this.isSearching = true;
      return new Promise((resolve, reject) =>
        searchDocumentsOnServer(query)
          .then(documents => {
            this.filteredDocuments = documents;
            resolve(documents);
          })
          .catch(error => {
            console.error('Error searching documents:', error);
            reject(error);
          })
      );
    },
    /**
     * Gets a signed URL for document download
     * @param {string} filePath - Path to the document file
     * @returns {Promise<string>} Signed URL
     * @throws {Error} If getting URL fails
     */
    async getSignedUrlForDocument(filePath) {
      try {
        return await getSignedUrlForDocument(filePath);
      } catch (error) {
        console.error('Error getting signed URL for document:', error);
        throw error;
      }
    },
    /**
     * Searches document tags
     * @param {string} query - Search query
     * @returns {Promise<Array>} Matching tags
     */
    async searchTags(query) {
      try {
        return await searchTags(query);
      } catch (error) {
        this.error = error.message;
        console.error('Error searching tags:', error);
      }
    },
    /**
     * Resets the store state
     */
    reset() {
      this.documents = [];
    },
    /**
     * Fetches all available tags
     * @returns {Promise<Array>} List of all tags
     * @throws {Error} If fetching tags fails
     */
    async getAllTags() {
      const {data, error} = await supabase
        .from('tags')
        .select('*')
        .order('name', {ascending: true});
      if (error) throw error;
      return data;
    },
    /**
     * Resubscribes to document updates if needed
     */
    async ensureSubscription() {
      if (!this.isSubscribed) {
        console.log('Ensuring subscription is active');
        await this.subscribeToDocumentUpdates();

        // Verify subscription was successful
        if (!this.isSubscribed) {
          console.error('Failed to establish subscription, will retry');
          await this.handleSubscriptionError();
        }
      }
    },
    /**
     * Handles subscription errors and reconnection
     */
    async handleSubscriptionError() {
      console.log('Handling subscription error');
      this.isSubscribed = false;
      this.subscription = null;

      // Wait a bit before trying to reconnect
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Try to resubscribe
      await this.ensureSubscription();
    }
  }
});
