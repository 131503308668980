import {defineStore} from 'pinia';
import {senderService} from '~/lib/services/sender.service.js';

export const useSenderStore = defineStore('sender', {
  state: () => ({
    allSenders: [],
    currentSender: null,
    isLoading: false,
    error: null
  }),

  actions: {
    async fetchAllSenders() {
      this.isLoading = true;
      try {
        this.allSenders = await senderService.getAllSenders();
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async fetchSenderById(id) {
      this.isLoading = true;
      try {
        this.currentSender = await senderService.getSenderById(id);
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async createSender(senderData) {
      this.isLoading = true;
      try {
        const newSender = await senderService.createSender(senderData);
        this.allSenders.push(newSender);
        return newSender;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async updateSender(id, senderData) {
      this.isLoading = true;
      try {
        const updatedSender = await senderService.updateSender(id, senderData);
        const index = this.allSenders.findIndex(sender => sender.id === id);
        if (index !== -1) {
          this.allSenders[index] = updatedSender;
        }
        if (this.currentSender && this.currentSender.id === id) {
          this.currentSender = updatedSender;
        }
        return updatedSender;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },

    async searchSenders(query) {
      this.isLoading = true;
      try {
        return await senderService.searchSenders(query);
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    }
  },

  getters: {
    getSenderById: state => id => state.allSenders.find(sender => sender.id === id)
  }
});
