export const senderService = {
  async getAllSenders() {
    const supabase = useSupabaseClient();
    const {data, error} = await supabase.from('senders').select('*');
    if (error) throw error;
    return data;
  },

  async getSenderById(id) {
    const supabase = useSupabaseClient();
    const {data, error} = await supabase.from('senders').select('*').eq('id', id).single();
    if (error) throw error;
    return data;
  },

  async createSender(senderData) {
    const supabase = useSupabaseClient();
    const user = useSupabaseUser();

    if (!user.value) throw new Error('User not authenticated');
    senderData.created_by = user.value.id;

    const {data, error} = await supabase.from('senders').insert(senderData).select().single();
    if (error) throw error;
    return data;
  },

  async updateSender(id, senderData) {
    const supabase = useSupabaseClient();
    const {data, error} = await supabase.from('senders').update(senderData).eq('id', id).select().single();
    if (error) throw error;
    return data;
  },

  async searchSenders(query) {
    const supabase = useSupabaseClient();
    const {data, error} = await supabase.from('senders').select('*').ilike('name', `%${query}%`);
    if (error) throw error;
    return data;
  }
};
